<template>
  <div id="about">
    <div
      class="container flex flex-col items-center justify-center h-screen mx-auto"
    >
      <h1 class="mb-20 text-3xl xl:text-7xl">our story</h1>
      <p
        class="px-2 leading-relaxed text-center xl:w-9/12 xl:text-2xl font-gimlet"
      >
        Prizma is the culmination of professionals uniting for their common
        passion in video games. With broad and diverse working histories our
        team members want to take their experiences and publish game titles with
        player satisfaction as the primary goal. We believe that being gamers
        ourselves enhances the enthusiasm and consideration we can deliver to
        our online community. We are a European based company with English as
        our primary language and central offices located within the chic heart
        of Italy; Milan.
      </p>
    </div>
  </div>
</template>

<style scoped>
h1 {
  letter-spacing: 0.8em;
  margin-right: -0.8em;
}

#about {
  background: url("../assets/bg 2.png") center no-repeat;
  background-size: cover;
  background-color: #001025;
}
</style>
